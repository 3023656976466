import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { GET_USER_TRANSACTION } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { getPaymentGatewayLogo, getReleaseDate } from '../../utils/helper'
import { isEmpty } from 'lodash'
import ViewTransactionDetailModal from './viewTransactionDetailModal'
import { withTheme } from 'theming'
// import CustomImage from '../../components/customImage/customImage'
// import HeroImg from '../../assets/pricing-page-hero.png'
import IButtonImg from '../../assets/i-button-icon.png'
import CopyImg from '../../assets/copy-button-icon.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const MyTransactions = ({ theme }) => {
  const { t } = useTranslation()
  const { setLoader } = useContext(AppContext)
  const navigate = useNavigate()
  const [detailShow, setDetailShow] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState({})
  const [transactions, setTransactions] = useState([])

  const [{ data: transactionData, loading: transactionDataLoading }, transactionDataCall] =
    useAxios(
      {
        url: GET_USER_TRANSACTION,
        method: 'get',
      },
      { manual: true },
    )

  useEffect(() => {
    transactionDataCall()
  }, [])

  useEffect(() => {
    setLoader(transactionDataLoading)
  }, [transactionDataLoading])

  useEffect(() => {
    if (transactionData && transactionData.success) {
      setTransactions(transactionData.data)
    }
  }, [transactionData])

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = (id) => {
    copyTextToClipboard(id)
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className="relative">
      <div className=" w-full flex justify-center ">
        <div className="mt-6 xl:mt-[115px] flex flex-col items-center w-full z-10">
          <p className="mb-0 text-white xl:text-[28px] font-bold uppercase">
            {' '}
            {t('my_transactions')}
          </p>
          <p style={{ color: '#b5b5b5' }} className="mb-0 text-xs lg:text-sm italic">
            {t('track_payment_history')}
          </p>
          <div
            className="mt-4 w-[94%] xl:w-[1038px] rounded-lg xl:rounded-xl  overflow-hidden"
            style={{
              backgroundImage: 'linear-gradient(to right, #000 8%, #00224a 94%)',
            }}
          >
            <div
              style={{ height: '36px', background: '#bbfa33' }}
              className="w-full flex lg:justify-center items-center font-bold pl-4 pr-4"
            >
              {t('transaction_details_caps')}
            </div>

            <div className="w-full">
              <div className=" h-[421px] lg:h-[544px] w-full xl:w-[1038px]">
                <div
                  style={{ height: '21px', background: '#617d26' }}
                  className="w-full hidden lg:flex items-center text-white text-xs pl-4 pr-4 lg:pl-[33px] lg:pr-[24px] "
                >
                  <div style={{ width: '15%' }}> {t('transaction_date')}</div>
                  <div style={{ width: '15%' }} className="text-center">
                    {t('total_amount')}
                  </div>
                  <div style={{ width: '25%' }} className="">
                    {t('plan')}(s)
                  </div>
                  <div style={{ width: '25%' }} className="">
                    {t('transaction_ID')}
                  </div>
                  <div style={{ width: '18%' }} className="">
                    {t('payment_gateway')}
                  </div>
                </div>
                <div className="h-[400px] lg:h-[523px] pl-4 pr-4 lg:pl-[33px] lg:pr-[24px] text-lg scrollbar-class">
                  {!isEmpty(transactions) ? (
                    [...transactions].map((info, index) => {
                      return (
                        <div
                          style={{ color: '#b5b5b5' }}
                          className="w-full lg:flex items-center mt-5 text-xs border-b lg:border-b-0 pb-2 lg:pb-0"
                          key={index}
                        >
                          <div className="text-white flex justify-between w-full lg:w-[15%]">
                            <div className="flex justify-between items-center lg:w-full">
                              <div
                                style={{ color: '#b5b5b5', width: '110px' }}
                                className="block lg:hidden"
                              >
                                {t('transaction_date')} :
                              </div>
                              <div className="ml-1 lg:w-full">
                                {getReleaseDate(info.captured_date)}
                              </div>
                            </div>
                            <img
                              style={{ width: '14px', height: '14px' }}
                              className="cursor-pointer block lg:hidden"
                              src={IButtonImg}
                              onClick={() => {
                                setSelectedTransaction(info)
                                setDetailShow(true)
                              }}
                              alt={'Details'}
                              title="Details"
                            />
                          </div>
                          <div className="mt-2 lg:mt-0 lg:text-center w-full lg:w-[15%] flex justify-between">
                            <div className="flex justify-between items-center  lg:w-full">
                              <div
                                style={{ color: '#b5b5b5', width: '110px' }}
                                className="block lg:hidden "
                              >
                                {t('total_amount')} :
                              </div>
                              <div className="ml-1 lg:w-full">
                                <span className="text-white mr-1">
                                  {parseFloat(info?.transaction_amount).toFixed(2)}{' '}
                                </span>
                                {info.currency}
                              </div>
                            </div>
                          </div>
                          <div
                            className=" text-white w-full lg:w-[25%] hidden lg:block"
                            title={[...info?.subscriptions]
                              .map((info) => info.subscription_name)
                              .toString()}
                          >
                            <div className="line-clamp-1  w-full">
                              {[...info?.subscriptions]
                                .map((info) => info.subscription_name)
                                .toString()}
                            </div>
                          </div>
                          <div className="mt-2 lg:mt-0 flex items-center justify-between w-full lg:w-[25%]">
                            <div className="flex justify-between items-center lg:w-full lg:hidden">
                              <div style={{ color: '#b5b5b5', width: '110px' }} className="">
                                {t('transaction_ID')} :
                              </div>
                              <div className="ml-1 lg:w-full ">
                                <div
                                  className="clamp-text-15-char lg:w-3/4"
                                  title={info.transaction_id}
                                >
                                  {info.transaction_id}
                                </div>
                              </div>
                            </div>

                            <div className=" lg:w-3/4 hidden lg:block" title={info.transaction_id}>
                              <div className="clamp-text-30-char">{info.transaction_id}</div>
                            </div>

                            <img
                              style={{ width: '14px', height: '14px' }}
                              src={CopyImg}
                              className="cursor-pointer ml-2 "
                              alt="CopyIcon"
                              title="Copy"
                              onClick={() => handleCopyClick(info.transaction_id)}
                            />
                          </div>
                          <div className="mt-2 lg:mt-0 flex justify-between items-center w-full lg:w-[18%]">
                            <div className="flex justify-between items-center lg:w-full">
                              <div
                                style={{ color: '#b5b5b5', width: '110px' }}
                                className="block lg:hidden"
                              >
                                {t('payment_gateway')} :
                              </div>
                              <div className="ml-1 w-[44%] lg:w-full">
                                <div
                                  style={{ width: '85px', height: '14px' }}
                                  className="bg-white rounded flex justify-center items-center"
                                >
                                  <img
                                    src={getPaymentGatewayLogo(info.payment_gateway)}
                                    alt={'stripe'}
                                    className="h-full"
                                  />
                                </div>
                              </div>
                            </div>

                            <img
                              style={{ width: '14px', height: '14px' }}
                              className="cursor-pointer hidden lg:block"
                              src={IButtonImg}
                              onClick={() => {
                                setSelectedTransaction(info)
                                setDetailShow(true)
                              }}
                              alt={'Details'}
                              title="Details"
                            />
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <>
                      <div className="font-bold w-full text-center text-xs xl:text-base mt-10 text-white">
                        {t('no_transactions_found')}
                      </div>
                      <div className="flex justify-center w-full mt-10">
                        <div
                          style={{
                            background: '#bbfa33',
                            color: '#0d0d0d',
                            height: '36px',
                            padding: '0px 15px',
                          }}
                          className="cursor-pointer text-xs xl:text-base font-semibold rounded flex items-center justify-center"
                          onClick={() => navigate('/pricing')}
                        >
                          {t('buy_membership_plan')}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="hidden xl:block absolute top-0 w-full"
        style={{
          aspectRatio: '1920/322',
        }}
      >
        <div className="relative w-full">
          {/* <CustomImage imageUrl={HeroImg} imgClass="w-full h-full" loaderClass={'h-64'} /> */}
          <div
            style={{
              height: '191px',
              backgroundImage: `linear-gradient(to bottom, rgba(27, 36, 47, 0), rgba(27, 36, 47, 0.91) 70%, ${theme.c1})`,
            }}
            className="w-full absolute bottom-0"
          />
        </div>
      </div>
      {detailShow && (
        <ViewTransactionDetailModal
          onCancel={() => {
            setSelectedTransaction({})
            setDetailShow(false)
          }}
          data={selectedTransaction}
        />
      )}
    </div>
  )
}

export default withTheme(MyTransactions)
