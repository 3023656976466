import React, { useContext, useEffect, useState } from 'react'
import { isEmpty } from '../../utils/helper'
import { useNavigate, useParams } from 'react-router'
import useAxios from 'axios-hooks'
import { CAPTURE_PAYMENT } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { withTheme } from 'theming'
// import SuccessImg from '../../assets/transaction-success-img.png'
import SuccessCheck from '../../assets/transaction-sucess-check.svg'
// import FailedImg from '../../assets/transaction-failed-img.png'
import FailedCross from '../../assets/transaction-failed-cross.svg'
import { useTranslation } from 'react-i18next'

const PaymentStatus = ({ theme }) => {
  const navigate = useNavigate()
  const data = useParams().data
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const [redirectionUrl, setRedirectionUrl] = useState('/')
  const [paymentStatus, setPaymentStatus] = useState('loading')
  const [timer, setTimer] = useState(20)
  const { t } = useTranslation()

  const [
    { data: capturePaymentRes, loading: capturePaymentLoading, error: capturePaymentError },
    capturePaymentCall,
  ] = useAxios(
    {
      url: CAPTURE_PAYMENT,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    setLoader(capturePaymentLoading)
  }, [capturePaymentLoading])

  useEffect(() => {
    if (capturePaymentRes) {
      if (capturePaymentRes?.success) {
        setRedirectionUrl(capturePaymentRes?.data.redirection_url)
        setPaymentStatus('success')
      } else {
        setPaymentStatus('failed')
      }
    }

    if (capturePaymentError && capturePaymentError.response.status !== 200) {
      setPaymentStatus('failed')
    }
  }, [capturePaymentRes, capturePaymentError])

  useEffect(() => {
    if (!isEmpty(data)) {
      const payload = JSON.parse(atob(data))
      capturePaymentCall({
        data: payload,
      })
    }
  }, [data])

  useEffect(() => {
    if (!capturePaymentLoading) {
      let myInterval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1)
        }
        if (timer === 0) {
          clearInterval(myInterval)
          window.location.replace(redirectionUrl)
        }
      }, 1000)

      return () => {
        clearInterval(myInterval)
      }
    }
  }, [timer, capturePaymentLoading])

  return (
    <div style={{ minHeight: '70vh' }} className="flex flex-col items-center justify-center">
      {paymentStatus === 'loading' && (
        <div style={{ color: isCustomTemplate ? theme.c4 : '#fff' }} className="text-2xl ">
          {t('please_select_any_one')} ...
        </div>
      )}
      {paymentStatus === 'success' && (
        <div className="w-[94%] xl:w-[1178px] xl:flex justify-center items-center">
          <div
            style={{ backgroundImage: 'linear-gradient(to top, #000 8%, #fd0000 94%)' }}
            className="h-[441px] w-full xl:w-[682px] xl:h-[414px] text-white rounded-xl pt-10 px-9 xl:pt-[63px] xl:px-[104px]"
          >
            <div className="flex justify-center">
              <img src={SuccessCheck} alt="SuccessImg" className="" />
            </div>
            <div className="flex justify-center mt-7 xl:mt-[18px] text-[22px] xl:text-[28px] font-bold ">
              {t('thank_you')} !
            </div>
            <div className="flex justify-center xl:text-lg mt-2.5 xl:mt-0 text-center">
              {t('transaction_is_successful')}.
            </div>
            <div className="flex justify-center text-xs xl:text-sm mt-6 xl:mt-11 text-center">
              {t('you_will_be_redirected_to_home_page')} {timer} {t('seconds')}.
            </div>
            <div className="flex justify-center text-xs xl:text-sm mt-4 xl:mt-2.5 text-center">
              {t('know_more_about_your_active_membership_plans_go_my_plans')}
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center  mt-10 xl:mt-9">
              <button
                style={{ backgroundColor: '#fd0000', color: '#fff' }}
                className="mx-5 px-4 py-1 text-white rounded focus:outline-none  w-[216px]"
                onClick={() => window.location.replace('/')}
              >
                {t('home')}
              </button>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: '1px solid #fd0000',
                }}
                className="mx-5  px-4 py-1 rounded focus:outline-none w-[216px] mt-8 text-center lg:mt-0"
                onClick={() => window.location.replace('/subscriptions')}
              >
                {t('my_plans')}
              </button>
            </div>
          </div>
          {/* <div className="flex justify-center">
            <img src={SuccessImg} alt="SuccessImg" className="w-[268px] xl:w-auto" />
          </div> */}
        </div>
      )}
      {paymentStatus === 'failed' && (
        <div className="w-[94%] xl:w-[1178px] xl:flex justify-center items-center">
          <div
            style={{ backgroundImage: 'linear-gradient(to top, #000 8%, #fd0000 94%)' }}
            className="h-[441px] w-full xl:w-[682px] xl:h-[414px] text-white rounded-xl pt-10 px-9 xl:pt-[63px] xl:px-[104px]"
          >
            <div className="flex justify-center">
              <img src={FailedCross} alt="SuccessImg" className="" />
            </div>
            <div className="flex justify-center mt-7 xl:mt-[18px] text-[22px] xl:text-[28px] font-bold ">
              {t('oops')} !
            </div>
            <div className="flex justify-center xl:text-lg mt-2.5 xl:mt-0 text-center">
              {t('payment_failed')}.
            </div>
            <div className="flex justify-center text-xs xl:text-sm mt-6 xl:mt-11 text-center">
              {t('transaction_failed_due_technical_error')}.
            </div>
            <div className="flex justify-center text-xs xl:text-sm mt-4 xl:mt-2.5 text-center">
              {t('please_try_again_You_will_be_redirected')} {`${timer}`} {t('seconds')}
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center  mt-10 xl:mt-9">
              <button
                style={{ backgroundColor: '#fd0000', color: '#fff' }}
                className="mx-5 px-4 py-1 text-white rounded focus:outline-none  w-[216px]"
                onClick={() => navigate('/')}
              >
                {t('home')}
              </button>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: '1px solid #fd0000',
                }}
                className="mx-5  px-4 py-1 rounded focus:outline-none w-[216px] mt-8 text-center lg:mt-0"
                onClick={() => navigate('/pricing')}
              >
                {t('retry')}
              </button>
            </div>
          </div>
          {/* <div className="flex justify-center">
            <img src={FailedImg} alt="SuccessImg" className="w-[268px] xl:w-auto" />
          </div> */}
        </div>
      )}
    </div>
  )
}

export default withTheme(PaymentStatus)
