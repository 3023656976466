import React, { useContext, useEffect, useState } from 'react'
import { withTheme } from 'theming'
import RadioButtonChecked from '../../assets/imgComponents/radioButtonChecked'
import RadioButtonUnchecked from '../../assets/imgComponents/radioButtonUnchecked'
import { SubscriptionContext } from '../../context/subscription'
import { useNavigate } from 'react-router'
import { isEmpty } from 'lodash'
import { raiseToast } from '../../utils/helper'
import useAxios from 'axios-hooks'
import { GET_MY_ACCESSIBLE_PLANS } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { useTranslation } from 'react-i18next'

const Subscription = ({ theme }) => {
  const activeColor = '#bbfa33'
  let navigate = useNavigate()
  const { t } = useTranslation()

  const [selectedSubscription, setSelectedSubscription] = useState({})
  const { setOpenSubscriptionModal, availableSubscription, subscriptions, setSubscriptions } =
    useContext(SubscriptionContext)

  const [finalSub, setFinalSub] = useState([])
  const [ppvData, setPPVData] = useState({})
  const [accessiblePlans, setAccessiblePlans] = useState({})
  const { setLoader, userObj, accountObj, setVerifyOtpModalData } = useContext(AppContext)

  const [{ data: accessiblePlansData, loading: accessiblePlansLoading }] = useAxios({
    url: GET_MY_ACCESSIBLE_PLANS,
    method: 'get',
  })

  useEffect(() => {
    if (accessiblePlansData && accessiblePlansData.success) {
      setAccessiblePlans(accessiblePlansData.data)
    }
  }, [accessiblePlansData])

  useEffect(() => {
    setLoader(accessiblePlansLoading)
  }, [accessiblePlansLoading])

  useEffect(() => {
    if (!isEmpty(availableSubscription)) {
      if (!isEmpty(subscriptions)) {
        let tempFinalData = availableSubscription.filter((item) => subscriptions.includes(item._id))

        setFinalSub(tempFinalData)
      } else {
        setFinalSub(availableSubscription.filter((item) => item?.is_pricing_visible))
      }
    }
  }, [availableSubscription, subscriptions])

  const handleProceed = () => {
    if (accountObj?.od_resource_allowances?.verify_email_mandatory && !userObj?.is_email_verified) {
      setVerifyOtpModalData({ title: t('verify_your_email'), email: userObj?.email_id })
      return
    }
    if (isEmpty(selectedSubscription)) {
      raiseToast('error', t('please_select_plan'))
      return
    }

    if (
      (isEmpty(ppvData) &&
        selectedSubscription.is_subscription_mandatory &&
        !isEmpty(selectedSubscription?.mandatory_subscription)) ||
      (!isEmpty(ppvData) &&
        ppvData.is_subscription_mandatory &&
        !isEmpty(ppvData?.mandatory_subscription))
    ) {
      const planExist = [...selectedSubscription?.mandatory_subscription]
        .map((item) => item?._id)
        .some((item) => item in accessiblePlans)

      if (!planExist && isEmpty(ppvData)) {
        setPPVData(selectedSubscription)
        setSubscriptions(selectedSubscription?.mandatory_subscription.map((item) => item._id))
        setSelectedSubscription({})
        return
      }

      if (!planExist && !isEmpty(ppvData) && !isEmpty(selectedSubscription)) {
        navigatePaymentPage(`/payment/${ppvData._id}?sub_id=${selectedSubscription._id}`)
        return
      }

      navigatePaymentPage(`/payment/${selectedSubscription._id}`)
      return
    }

    navigatePaymentPage(`/payment/${selectedSubscription._id}`)
  }

  const navigatePaymentPage = (path) => {
    setOpenSubscriptionModal(false)
    navigate(`${path}`)
  }

  return (
    <div className="">
      <div
        style={{
          backgroundImage: 'linear-gradient(to right, #000 8%, #00224a 94%)',
        }}
        className={`rounded-xl`}
      >
        {!isEmpty(ppvData) ? (
          <div className="">
            <div
              className={`flex w-full cursor-pointer pl-2.5 pt-2.5 pr-4 lg:pl-[24px] lg:pr-[40px] lg:pt-[27px]`}
            >
              <div className="w-[8%] lg:w-[6.2%] flex lg:items-center"></div>
              <div className="w-[92%] lg:w-[93.8%] flex flex-col">
                <div className="flex justify-between" style={{ color: '#fff' }}>
                  <div className="lg:w-3/5 flex flex-col lg:flex-row justify-between">
                    <p className="mb-0 font-bold">{ppvData.name}</p>
                  </div>
                  <div className="lg:w-2/5 flex justify-end items-center">
                    <p className="mb-0 font-bold">
                      {parseFloat(ppvData?.amount).toFixed(2)}

                      <span style={{ color: '#b5b5b5' }} className="text-xs ml-1 font-normal">
                        {ppvData?.currency}
                      </span>
                    </p>
                  </div>
                </div>
                <div style={{ color: '#b5b5b5' }} className="text-xs mt-2">
                  {ppvData.description}
                </div>
              </div>
            </div>
            <div
              style={{ background: '#bbfa33' }}
              className="flex mt-5 pl-2.5 pr-4 py-1 lg:pl-[24px] lg:pr-[40px] "
            >
              <div className="w-[8%] lg:w-[6.2%] flex lg:items-center"></div>
              <div className="w-[92%] lg:w-[93.8%] flex flex-col">
                <p className="font-bold">{t('active_membership_required')}</p>
                <div style={{ color: '#575757' }} className="text-xs mt-1">
                  {t('please_select_any_one')}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`scrollbar-class w-full rounded-xl pb-4 pl-2.5 pt-2.5 pr-4 lg:pl-[24px] lg:pr-[40px] lg:pt-[27px] max-h-[520px] lg:max-h-[398px]`}
        >
          {!isEmpty(finalSub) &&
            [...finalSub].map((info, index) => {
              let isActive = info?._id === selectedSubscription?._id
              let isAlreadyExist = info?._id in accessiblePlans
              return (
                <div
                  className={`flex w-full ${index && 'mt-7'} cursor-pointer`}
                  key={index}
                  style={isAlreadyExist ? { opacity: '0.4' } : {}}
                  onClick={() => (isAlreadyExist ? {} : setSelectedSubscription(info))}
                >
                  <div className="w-[8%] lg:w-[6.2%] flex lg:items-center">
                    {isActive ? (
                      <RadioButtonChecked imgClass="mt-1 lg:mt-0" />
                    ) : (
                      <RadioButtonUnchecked imgClass="mt-1 lg:mt-0" />
                    )}
                  </div>
                  <div className="w-[92%] lg:w-[93.8%] flex flex-col">
                    <div
                      className="flex justify-between"
                      style={{ color: isActive ? activeColor : '#fff' }}
                    >
                      <div className="lg:w-3/5 flex flex-col lg:flex-row justify-between">
                        <p className="mb-0 font-bold subscription-title-width ">{info.name}</p>
                        <div className="xl:w-[115px]">
                          <p
                            className="mb-0 text-xs mt-1"
                            style={{
                              color: isActive ? activeColor : '#b5b5b5',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('validity')}
                            <span
                              className="ml-2 font-bold"
                              style={{ color: isActive ? activeColor : '#fff' }}
                            >
                              {info.duration} {t('days')}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="lg:w-2/5 flex justify-end items-center">
                        <p className={`mb-0 font-bold ${isAlreadyExist && 'text-xs'}`}>
                          {isAlreadyExist
                            ? accessiblePlans[info?._id]
                            : parseFloat(info?.amount).toFixed(2)}

                          <span
                            style={{ color: isActive ? activeColor : '#b5b5b5' }}
                            className="text-xs ml-1 font-normal"
                          >
                            {!isAlreadyExist && info?.currency}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div style={{ color: '#b5b5b5' }} className="text-xs mt-2">
                      {info.description}
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      <div className="w-full flex justify-center">
        <div
          style={{ background: '#fd0000', color: '#fff', height: '36px' }}
          className="mt-6 cursor-pointer  w-full lg:w-[470px] font-bold rounded flex items-center justify-center"
          onClick={handleProceed}
        >
          {t('proceed_to_pay')}
        </div>
      </div>
    </div>
  )
}

export default withTheme(Subscription)
